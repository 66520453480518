<template>
    <Suscriptions />
</template>
<script>
import Suscriptions from "@/components/suscriptions/Suscriptions.vue";
export default {
  name: "Suscripciones",
  components: {
    Suscriptions,
  },
};
</script>
